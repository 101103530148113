[
  {
    "id": "brainrot_at_the_twitch_chat",
    "characters": {
      "sigma": {
        "ability": "You cannot die by execution.",
        "homebrew": {
          "name": "Sigma",
          "roleType": "townsfolk"
        }
      },
      "looksmaxxer": {
        "ability": "Once per day, the person who nominates you becomes sober and healthy.",
        "homebrew": {
          "name": "Looksmaxxer",
          "roleType": "townsfolk"
        }
      },
      "hawktuahgirl": {
        "ability": "After the 3rd day, you die.",
        "homebrew": {
          "name": "Hawk Tuah Girl",
          "roleType": "townsfolk",
          "otherNightsIndex": 11
        },
        "otherNights": "If 3 days have passed, Hawk Tuah Girl dies."
      },
      "fanumtaxer": {
        "ability": "Once per game, choose a player at night. You gain their ability. If evil, you die.",
        "homebrew": {
          "name": "Fanum Taxer",
          "roleType": "townsfolk",
          "firstNightIndex": 6,
          "otherNightsIndex": 8
        },
        "firstNight": "The Fanum Taxer shows a 'no' head signal, or chooses a player: if evil, Fanum Taxer dies. Otherwise, gain their ability.",
        "otherNights": "The Fanum Taxer shows a 'no' head signal, or chooses a player: if evil, Fanum Taxer dies. Otherwise, gain their ability."
      },
      "mogger": {
        "ability": "Once per game, publically choose a dead player. They can nominate and vote normally.",
        "homebrew": {
          "name": "Mogger",
          "roleType": "townsfolk"
        }
      },
      "costcoguy": {
        "ability": "Each day, publically choose a player. They cannot die. Cannot choose yourself.",
        "homebrew": {
          "name": "Costco Guy",
          "roleType": "townsfolk"
        }
      },
      "mewer": {
        "ability": "Each day, you must publically \"mew\". If your mewing streak ends, you die. Mewing prevents you from dying at night.",
        "homebrew": {
          "name": "Mewer",
          "roleType": "townsfolk"
        }
      },
      "ohioan": {
        "ability": "You start knowing a random role in play.",
        "homebrew": {
          "name": "Ohioan",
          "roleType": "townsfolk",
          "firstNightIndex": 4
        },
        "firstNight": "Show the Ohioan 1 in-play role."
      },
      "lightskin": {
        "ability": "Pick a player first night*. They cannot be poisoned",
        "homebrew": {
          "name": "Lightskin",
          "roleType": "townsfolk"
        }
      },
      "streamer": {
        "ability": "Once per game, publicly choose a player. Everybody learns their role.",
        "homebrew": {
          "name": "Streamer",
          "roleType": "townsfolk"
        }
      },
      "simp": {
        "ability": "You start knowing if a random townsfolk is good.",
        "homebrew": {
          "name": "Simp",
          "roleType": "townsfolk",
          "firstNightIndex": 5
        },
        "firstNight": "Point to the marked player."
      },
      "redditor": {
        "ability": "Each night, pick a player. Their vote counts as a downvote [negatively].",
        "homebrew": {
          "name": "Redditor",
          "roleType": "townsfolk",
          "firstNightIndex": 7,
          "otherNightsIndex": 9
        },
        "firstNight": "The Redditor points to a player.",
        "otherNights": "The Redditor points to a player."
      },
      "edgar": {
        "ability": "Each night, you learn a player's alignment in a clockwise direction.",
        "homebrew": {
          "name": "Edgar",
          "roleType": "townsfolk",
          "firstNightIndex": 8,
          "otherNightsIndex": 10
        },
        "firstNight": "Wake Edgar and show thumbs up or thumbs down.",
        "otherNights": "Wake Edgar and show thumbs up or thumbs down."
      },
      "racist": {
        "ability": "If there are more outsiders [+travelers] than townsfolk, you become evil.",
        "homebrew": {
          "name": "Racist",
          "roleType": "outsider"
        }
      },
      "gooner": {
        "ability": "Each night, the 1st player to choose you with their ability is drunk until dusk. You become their alignment.",
        "homebrew": {
          "name": "Gooner",
          "roleType": "outsider"
        }
      },
      "Furry": {
        "ability": "If a townsfolk picks you at night, they become a furry too. If one furry dies, the rest do too.",
        "homebrew": {
          "name": "Furry",
          "roleType": "outsider",
          "firstNightIndex": 9,
          "otherNightsIndex": 12
        },
        "firstNight": "If a townsfolk picked the Furry tonight: replace that player's character token with a Furry token. Show that player YOU ARE and the Furry token.",
        "otherNights": "If a townsfolk picked the Furry tonight: replace that player's character token with a Furry token. Show that player YOU ARE and the Furry token."
      },
      "delulu": {
        "ability": "You think you are a minion. You don't know that you are delulu.",
        "homebrew": {
          "name": "Delulu",
          "roleType": "outsider",
          "firstNightIndex": 3,
          "otherNightsIndex": 4
        },
        "firstNight": "If 7 or more players: Show THIS IS THE DEMON token. Point to the \"Demon\". Show THESE ARE YOUR MINIONS token. Point to \"Minions\". Put the Delulu to sleep..",
        "otherNights": "Do whatever is needed to simulate the minion."
      },
      "skibiditoilet": {
        "ability": "Your townsfolk neighbors are poisoned until you die.",
        "homebrew": {
          "name": "Skibidi Toilet",
          "roleType": "minion"
        }
      },
      "rizzler": {
        "ability": "Once per game*, choose 2 players. Their abilities are swapped.",
        "homebrew": {
          "name": "Rizzler",
          "roleType": "minion",
          "otherNightsIndex": 1
        },
        "otherNights": "The Rizzler either shows a 'no' head signal, or points to two players."
      },
      "grimace": {
        "ability": "Each night*, choose a palyer. They are poisoned. If already poisoned, they die.",
        "homebrew": {
          "name": "Grimace",
          "roleType": "minion",
          "otherNightsIndex": 2
        },
        "otherNights": "The Grimace picks a player to poison. If that player is already poisoned, they die."
      },
      "joker": {
        "ability": "Each night*, choose a player. You get to choose what information to give them.",
        "homebrew": {
          "name": "Joker",
          "roleType": "minion",
          "otherNightsIndex": 3
        },
        "otherNights": "The Joker picks a player. If that player wakes, ask the Joker what to tell them if they learn any information."
      },
      "imposter": {
        "ability": "Each night*, choose a player: they die. You register as good.",
        "homebrew": {
          "name": "Imposter",
          "roleType": "demon",
          "otherNightsIndex": 5
        },
        "otherNights": "The Imposter kills a player."
      },
      "thickofit": {
        "ability": "Each time someone is nominated, you gain a kill that night. If nobody is nominated, you cannot kill.",
        "homebrew": {
          "name": "Thick of It",
          "roleType": "demon",
          "otherNightsIndex": 6
        },
        "otherNights": "The Thick of It chooses a number of players equal to the number of nominations that occurred today. Those players die."
      },
      "babygronk": {
        "ability": "Every other night*, Choose a player, they die. You add 2 minions in play.",
        "homebrew": {
          "name": "Baby Gronk",
          "roleType": "demon",
          "otherNightsIndex": 7
        },
        "otherNights": "If it is an even-numbered night: the Baby Gronk chooses a player: they die."
      }
    },
    "script": {
      "pk": 14419,
      "title": "Brainrot at the Twitch Chat",
      "author": "Steven Medway",
      "score": 0,
      "characters": [
        "sigma",
        "looksmaxxer",
        "hawktuahgirl",
        "fanumtaxer",
        "mogger",
        "costcoguy",
        "mewer",
        "ohioan",
        "lightskin",
        "streamer",
        "simp",
        "redditor",
        "edgar",
        "racist",
        "gooner",
        "Furry",
        "delulu",
        "skibiditoilet",
        "rizzler",
        "grimace",
        "joker",
        "imposter",
        "thickofit",
        "babygronk"
      ]
    }
  },
  {
    "id": "fall_of_rome",
    "characters": {
      "sculptor_fallofrome": {
        "ability": "You start knowing a player. Each night*, you learn the alignment of their most recent nomination.",
        "homebrew": {
          "name": "Sculptor",
          "roleType": "townsfolk",
          "firstNightIndex": 21,
          "otherNightsIndex": 19
        },
        "firstNight": "Point to a player. Place the SCULPTURE token next to that player.",
        "otherNights": "If the player with the SCULPTURE token nominated today. Show the Sculptor an evil thumbs down sign if the nominated player is evil. Otherwise: show the Sculptor a good thumbs up sign. Remove the NOMINATED token (if any)."
      },
      "vestalvirgin_fallofrome": {
        "ability": "You start knowing 1 good & 1 evil character, 1 of which is in-play. When they die, that night you learn 1 good & 1 evil character, 1 of which is in-play.",
        "homebrew": {
          "name": "Vestal Virgin",
          "roleType": "townsfolk",
          "firstNightIndex": 22,
          "otherNightsIndex": 20
        },
        "firstNight": "Show 1 good and 1 evil character token: one of these is in play. Place the LEARNS next to the in-play character. ",
        "otherNights": "If the player with the LEARNS token died today, remove the token. Show 1 good and 1 evil character token: one of these is in play. Place the LEARNS next to the in-play character. "
      },
      "physician_fallofrome": {
        "ability": "Each night, choose two players (not yourself): they are sober, healthy & get true info tonight. The 1st time the Demon kills one, you learn the Demon type.",
        "homebrew": {
          "name": "Physician",
          "roleType": "townsfolk",
          "firstNightIndex": 20,
          "otherNightsIndex": 11
        },
        "firstNight": "The Physician points to two players not themselves. Mark both players with the PATIENT token.",
        "otherNights": "Remove all PATIENT tokens. The Physician points to two players not themselves. Mark both players with the PATIENT token. If a player with a PATIENT token was killed by the Demon, place the 1ST DEMON token next to the Physician and wake the Physician and show them Demon token."
      },
      "legionary_fallofrome": {
        "ability": "Each night, you learn how many living evil players are sat clockwise between yourself and a living Legionary. [+0 to +2 Legionary]",
        "homebrew": {
          "name": "Legionary",
          "roleType": "townsfolk",
          "firstNightIndex": 23,
          "otherNightsIndex": 21
        },
        "firstNight": "Show the hand signal for the number (0, 1, 2, etc.) of living evil players between the Legionary and the next clockwise Legionary (exclusive).",
        "otherNights": "Show the hand signal for the number (0, 1, 2, etc.) of living evil players between the Legionary and the next clockwise Legionary (exclusive)."
      },
      "trumpeter_fallofrome": {
        "ability": "Each night*, you learn how many evil players publicly claimed to be Spartacus today.",
        "homebrew": {
          "name": "Trumpeter",
          "roleType": "townsfolk",
          "otherNightsIndex": 22
        },
        "otherNights": "Show the hand signal for the number (0, 1, 2, etc.) of evil players who publicly claimed to be Spartacus today."
      },
      "mortician_fallofrome": {
        "ability": "Each night*, if a player died by execution today you learn if either of their living neighbours are evil.",
        "homebrew": {
          "name": "Mortician",
          "roleType": "townsfolk",
          "otherNightsIndex": 23
        },
        "otherNights": "If a player was executed today: Show the head signal (nod 'yes', shake 'no') for whether one of the neighbours is evil."
      },
      "standardbearer_fallofrome": {
        "ability": "When you are nominated, you may make a unique public statement about the nominator (not yourself). Tonight, you learn if the statement was true.",
        "homebrew": {
          "name": "Standard Bearer",
          "roleType": "townsfolk",
          "otherNightsIndex": 24
        },
        "otherNights": "If the Standard Bearer's public statement was true: show the head signal (nod 'true'). Otherwise: show the head signal (shake 'false')."
      },
      "centurion1_fallofrome": {
        "ability": "If you nominate & execute a living player, their team loses. You are safe from the Demon. If you publicly claimed to be Spartacus today, you are drunk until dawn.",
        "homebrew": {
          "name": "Centurion",
          "roleType": "townsfolk"
        }
      },
      "merchant_fallofrome": {
        "ability": "Once per game, at night, choose to learn the characters of players that have nominated you.",
        "homebrew": {
          "name": "Merchant",
          "roleType": "townsfolk",
          "otherNightsIndex": 26
        },
        "otherNights": "If the Merchant has not yet used their ability: the Merchant either shows a shake 'no' head signal, or a nod 'yes' head signal. If the Merchant chose 'yes', show the character tokens of players with a NOMINATED token (in any order). Place the NO ABILITY token."
      },
      "gladiator_fallofrome": {
        "ability": "Once per game, during the day, publicly choose a living player. Tonight, you and they wake & silently play roshambo: whoever loses dies (someone must lose).",
        "homebrew": {
          "name": "Gladiator",
          "roleType": "townsfolk",
          "otherNightsIndex": 18
        },
        "otherNights": "If the Gladiator used their ability today wake the Gladiator and the player they chose: both players silently play roshambo (rock \"fist\", scissors \"two fingers\" or paper \"flat hand\"). If they both chose the same, play again. Play until someone wins. The loser dies."
      },
      "actor_fallofrome": {
        "ability": "Once per game, during the day, publicly guess 3 players' character types (not yourself, 1 guess per type). That night, you learn how many you got correct. ",
        "homebrew": {
          "name": "Actor",
          "roleType": "townsfolk",
          "otherNightsIndex": 25
        },
        "otherNights": "If the Actor used their ability today: Show the hand signal for the number (0, 1, 2, or 3) of CORRECT markers. Place the NO ABILITY marker."
      },
      "blacksmith_fallofrome": {
        "ability": "The 1st time the Demon kills you, you live & gain a not-in-play Townsfolk ability.",
        "homebrew": {
          "name": "Blacksmith",
          "roleType": "townsfolk",
          "otherNightsIndex": 17
        },
        "otherNights": "If the Blacksmith was killed by a Demon. Show the Blacksmith a not-in-play Townsfolk character token. Swap the not-in-play character token with the Blacksmith token. Place the IS THE BLACKSMITH token next to the not-in-play character token. Wake the Blacksmith and show 'You are', then their new character ability."
      },
      "scholar_fallofrome": {
        "ability": "The 1st time you nominate a living Outsider, they immediately become a not-in-play Townsfolk. [+1 Outsider]",
        "homebrew": {
          "name": "Scholar",
          "roleType": "townsfolk",
          "otherNightsIndex": 2
        },
        "otherNights": "If the Scholar nominated an Outsider today, place the NO ABILITY token. Wake the nominated player. Show 'You are', then their new character token."
      },
      "thetwins_fallofrome": {
        "ability": "You start knowing a player: if either of you are executed, all Townsfolk are drunk until dusk tomorrow.",
        "homebrew": {
          "name": "The Twins",
          "roleType": "outsider",
          "firstNightIndex": 19,
          "otherNightsIndex": 10
        },
        "firstNight": "Point to a player. Place the REMUS token next to that player.",
        "otherNights": "If The Twins has a TOWNSFOLK DRUNK token, all Townsfolk are drunk until dusk."
      },
      "winemaker_fallofrome": {
        "ability": "Your Townsfolk neighbours are drunk, but every other night, you are drunk until dusk, even if you are dead.",
        "homebrew": {
          "name": "Winemaker",
          "roleType": "outsider",
          "firstNightIndex": 18,
          "otherNightsIndex": 9
        },
        "firstNight": "Place either the ODD or EVEN token. If ODD, the Winemaker is drunk on the 1st night and every other night after. Otherwise: the Winemaker is drunk on the 2nd night and every other night after.",
        "otherNights": "Check which ODD or EVEN token is placed. If an odd night and the ODD token is placed, the Winemaker is drunk until dusk. If an even night and the EVEN token is placed, the Winemaker is drunk until dusk. Otherwise their Townsfolk neighbours are drunk until dusk."
      },
      "spartacus_fallofrome": {
        "ability": "If an evil player guesses you (once), your team loses. You might register as a Townsfolk; each day, if you did not publicly claim to be Spartacus, you don't.",
        "homebrew": {
          "name": "Spartacus",
          "roleType": "outsider"
        }
      },
      "badomen_fallofrome": {
        "ability": "You do not know you are a Bad Omen. You think you are a Townsfolk, but you receive false information. You might register as evil, even if dead.",
        "homebrew": {
          "name": "Bad Omen",
          "roleType": "outsider"
        }
      },
      "temptress_fallofrome": {
        "ability": "On your 1st night choose two players: they learn that they were chosen. The 1st time one of them dies by execution, the other becomes evil that night.",
        "homebrew": {
          "name": "Temptress",
          "roleType": "minion",
          "firstNightIndex": 15,
          "otherNightsIndex": 3
        },
        "firstNight": "The Temptress points to two players. Place the SEDUCED token next to the two players. Wake the two players separately. Show the 'This character selected you' card, then the Temptress token. ",
        "otherNights": "If a player with a SEDUCED token is executed and dies, remove their SEDUCED token. The player with the remaining SEDUCED turns evil. Remove their SEDUCED token and replace it with the EVIL token. Wake the evil player and show them the 'You are' card and the thumbs down evil signal."
      },
      "haruspex_fallofrome": {
        "ability": "Each night, choose a player: you learn their character. The 1st player you choose twice in this way, dies. [+ Spartacus]",
        "homebrew": {
          "name": "Haruspex",
          "roleType": "minion",
          "firstNightIndex": 17,
          "otherNightsIndex": 16
        },
        "firstNight": "The Haruspex points to a player. Show that player’s character token. Place a FORETOLD token next that player. ",
        "otherNights": "The Haruspex points to a player. Show that player’s character token. Place a FORETOLD token next that player: if that player already has a FORETOLD token. That player dies, also place the CAN'T KILL token next to the Haruspex."
      },
      "glykon1_fallofrome": {
        "ability": "You might register as good. Until dawn, players you nominate register as the opposing alignment & if a Townsfolk, are also poisoned.",
        "homebrew": {
          "name": "Glykon",
          "roleType": "minion"
        }
      },
      "augur_fallofrome": {
        "ability": "If a Townsfolk nominates you, they immediately become a Bad Omen.",
        "homebrew": {
          "name": "Augur",
          "roleType": "minion"
        }
      },
      "cleopatra_fallofrome": {
        "ability": "Each night, choose two players: if they nominate tomorrow, they die that night. Each day, if a good player (Travellers don't count) does not nominate, evil wins. ",
        "homebrew": {
          "name": "Cleopatra",
          "roleType": "demon",
          "firstNightIndex": 16,
          "otherNightsIndex": 12
        },
        "firstNight": "Cleopatra points to two players. ",
        "otherNights": "If a previously chosen player nominated today they die. Remove all CHOSEN tokens. Cleopatra points to two players. Place the CHOSEN token next to both players."
      },
      "crassus_fallofrome": {
        "ability": "Each night*, choose a player: they die. If the 1st Crassus publicly claims to be Spartacus & dies with 5 or more players alive, an evil player becomes Crassus.",
        "homebrew": {
          "name": "Crassus",
          "roleType": "demon",
          "otherNightsIndex": 13
        },
        "otherNights": "Crassus points to a player. That player dies. If the 1st Crassus publicly claimed to be Spartacus today and points to themselves (or was executed), with 5 or more players alive, replace the character of 1 evil player with a spare Crassus token. Show the 'You are' card, then the Crassus token. "
      },
      "hannibal_fallofrome": {
        "ability": "You think you are a good character, but you are not. Minions learn 3 bluffs. Each night*, a player might die. The 1st Hannibal to die, becomes good. [+1 Hannibal] ",
        "homebrew": {
          "name": "Hannibal",
          "roleType": "demon",
          "firstNightIndex": 14,
          "otherNightsIndex": 14
        },
        "firstNight": "Select 2 good players and place the IS HANNIBAL reminder tokens. Wake all Minions together, show them the Hannibals and show them three not-in-play character tokens (these must be the same).",
        "otherNights": "Choose a player, that player dies. If a Hannibal died today, wake that player and show them the 'You are' card, the Hannibal token and then the thumbs up good signal."
      },
      "caesar_fallofrome": {
        "ability": "Each night*, choose a player: they die. The 1st time an evil player dies by execution, that night, choose an additional player: they die.",
        "homebrew": {
          "name": "Caesar",
          "roleType": "demon",
          "otherNightsIndex": 15
        },
        "otherNights": "If an evil player was executed today, Caesar points to two players. Otherwise: Caesar points to a player. Chosen players die."
      },
      "mercenary_fallofrome": {
        "ability": "Each night*, gain the ability of a player who publicly claimed Spartacus today. If a Mercenary is exiled, you are exiled too. [+1 Mercenary of opposing alignment]",
        "homebrew": {
          "name": "Mercenary",
          "roleType": "travellers",
          "otherNightsIndex": 4
        },
        "otherNights": "Show the Mercenary a character that publicly claimed to be Spartacus today, they have that ability tonight and tomorrow until dusk. "
      },
      "architect_fallofrome": {
        "ability": "Each night*, choose a player: 1) they become a not-in-play character of the same type, or 2) they swap characters with a player of the same type.",
        "homebrew": {
          "name": "Architect",
          "roleType": "travellers",
          "otherNightsIndex": 7
        },
        "otherNights": "The Architect points to a player. Choose which ability will effect the chosen player. Treat them accordingly (not-in-play character or character swap). Wake affected players individually. Show 'You are', then their new character token."
      },
      "sibyl_fallofrome": {
        "ability": "Each day, after the 1st execution, you may publicly choose a dead player: they may nominate. If the majority of the dead and yourself agree, they are executed.",
        "homebrew": {
          "name": "Sibyl",
          "roleType": "travellers"
        }
      },
      "highpriest1_fallofrome": {
        "ability": "Each day, publicly choose a unique living player to bless: if a majority of players agree, something good happens to them.",
        "homebrew": {
          "name": "High Priest",
          "roleType": "travellers",
          "otherNightsIndex": 5
        },
        "otherNights": "If the High Priest successfully blessed a player today, something good happens to that player and/or their team."
      },
      "highpriest2_fallofrome": {
        "ability": "Each day, publicly choose a unique living player to bless: if a majority of players agree, tomorrow they may learn a statement. Tonight, choose if it’s true.",
        "homebrew": {
          "name": "High Priest",
          "roleType": "travellers",
          "otherNightsIndex": 6
        },
        "otherNights": "If the High Priest successfully blessed a player today, wake the High Priest. The High Priest chooses whether the statement is true tomorrow with a hand signal (true \"thumbs up\", false \"thumbs down\")."
      },
      "emperor_fallofrome": {
        "ability": "Each day, choose the 1st execution's outcome. If you choose to protect today's execution: they survive. Otherwise, tonight you learn their alignment.",
        "homebrew": {
          "name": "Emperor",
          "roleType": "travellers",
          "otherNightsIndex": 8
        },
        "otherNights": "If a player was executed today and the outcome was not overturned by the Emperor: Show the hand signal (thumbs down 'evil', thumbs up 'good') for the players alignment."
      },
      "iamspartacus_fallofrome": {
        "ability": "Each day, up to half the living players (rounded up & Travellers don’t count) may publicly claim to be Spartacus.",
        "homebrew": {
          "name": "I Am Spartacus!",
          "roleType": "fabled"
        }
      }
    },
    "script": {
      "pk": 24823,
      "title": "Fall of Rome",
      "author": "Alex S",
      "score": 0,
      "characters": [
        "sculptor_fallofrome",
        "vestalvirgin_fallofrome",
        "physician_fallofrome",
        "legionary_fallofrome",
        "trumpeter_fallofrome",
        "mortician_fallofrome",
        "standardbearer_fallofrome",
        "centurion1_fallofrome",
        "merchant_fallofrome",
        "gladiator_fallofrome",
        "actor_fallofrome",
        "blacksmith_fallofrome",
        "scholar_fallofrome",
        "thetwins_fallofrome",
        "winemaker_fallofrome",
        "spartacus_fallofrome",
        "badomen_fallofrome",
        "temptress_fallofrome",
        "haruspex_fallofrome",
        "glykon1_fallofrome",
        "augur_fallofrome",
        "cleopatra_fallofrome",
        "crassus_fallofrome",
        "hannibal_fallofrome",
        "caesar_fallofrome",
        "mercenary_fallofrome",
        "architect_fallofrome",
        "sibyl_fallofrome",
        "highpriest1_fallofrome",
        "highpriest2_fallofrome",
        "emperor_fallofrome",
        "iamspartacus_fallofrome"
      ]
    }
  },
  {
    "id": "pedagogic_pits_&_pendulums",
    "characters": {
      "démone_ppp": {
        "ability": "You start knowing which Demon is in-play. If there are 3 Demons in-play, good wins.",
        "homebrew": {
          "name": "Démone",
          "roleType": "townsfolk",
          "firstNightIndex": 8
        },
        "firstNight": "Show an in-play Demon character token."
      },
      "minion_ppp": {
        "ability": "If you are executed, a Townsfolk gains a not in-play Minion ability & changes alignment. [-1 Minion]",
        "homebrew": {
          "name": "Minion",
          "roleType": "townsfolk",
          "otherNightsIndex": 7
        },
        "otherNights": "If the Minion was executed today, show a Townsfolk the THIS CHARACTER SELECTED YOU & Minion token. Show the YOU ARE token & give a thumbs up or thumbs down."
      },
      "notanoutsider_ppp": {
        "ability": "You start knowing 3 true statements. 1 of them, if said publicly, will cause you to join the team that loses, even if you are dead.",
        "homebrew": {
          "name": "Not-An-Outsider",
          "roleType": "townsfolk",
          "firstNightIndex": 9
        },
        "firstNight": "Show 3 true statements. Put the Not-An-Outsider to sleep. Mark 1 statement as 'LOSER'."
      },
      "evil_ppp": {
        "ability": "Each night, choose 2 players: learn how many are evil. You must choose new players each night.",
        "homebrew": {
          "name": "Evil",
          "roleType": "townsfolk",
          "firstNightIndex": 10,
          "otherNightsIndex": 13
        },
        "firstNight": "The Evil chooses 2 players. Give a finger signal.",
        "otherNights": "The Evil chooses 2 players. Give a finger signal."
      },
      "acharacter_ppp": {
        "ability": "You think you are a good character not on this script, & might have their ability. Each night you might become a good character not on this script.",
        "homebrew": {
          "name": "A Character",
          "roleType": "townsfolk",
          "firstNightIndex": 7,
          "otherNightsIndex": 6
        },
        "firstNight": "The A Character chooses a player or is shown any information needed based on the ability. The A character might wake a 2nd time. Show the YOU ARE token & their new character token.",
        "otherNights": "The A Character chooses a player or is shown any information needed based on the ability. The A character might wake a 2nd time. Show the YOU ARE token & their new character token."
      },
      "poisoned_ppp": {
        "ability": "Each night*, choose an alive player: either you or they are poisoned til dusk. You learn their alignment.",
        "homebrew": {
          "name": "Poisoned",
          "roleType": "townsfolk",
          "otherNightsIndex": 4
        },
        "otherNights": "The Poisoned chooses a living player. Give a thumbs-up or thumbs-down."
      },
      "townsfolk_ppp": {
        "ability": "Each day, you may visit the Storyteller to learn if you become a Townsfolk.",
        "homebrew": {
          "name": "Townsfolk",
          "roleType": "townsfolk",
          "otherNightsIndex": 8
        },
        "otherNights": "The Townsfolk chooses a player or is shown any information needed based on the ability."
      },
      "storyteller_ppp": {
        "ability": "Each day you may visit the Storyteller, & choose the outcome of the next \"might\" ability.",
        "homebrew": {
          "name": "Storyteller",
          "roleType": "townsfolk"
        }
      },
      "hardclaiming_ppp": {
        "ability": "Once per game, during the day, publicly choose a character, if that character dies tonight, good wins.",
        "homebrew": {
          "name": "Hard Claiming",
          "roleType": "townsfolk",
          "otherNightsIndex": 18
        },
        "otherNights": "If the Hard Claim is dead, announce good wins."
      },
      "outsider_ppp": {
        "ability": "If the Demon kills you, the Storyteller will flash the Grimoire at you, but with characters out of order.",
        "homebrew": {
          "name": "Outsider",
          "roleType": "townsfolk",
          "otherNightsIndex": 16
        },
        "otherNights": "If the Outsider died tonight, set-up Grimoire as needed. Show the Grimoire for 1 second. Reset Grimoire."
      },
      "executed_ppp": {
        "ability": "If you are executed, tonight learn 2 characters of the players that voted today.",
        "homebrew": {
          "name": "Executed",
          "roleType": "townsfolk",
          "otherNightsIndex": 14
        },
        "otherNights": "If Executed was executed today, show 2 character tokens that voted."
      },
      "notdead_ppp": {
        "ability": "If you die, learn a dead character. If you are executed twice, you might be resurrected tonight.",
        "homebrew": {
          "name": "Not Dead",
          "roleType": "townsfolk",
          "otherNightsIndex": 15
        },
        "otherNights": "If the Not Dead died, show a dead character token. If the Not Dead was executed twice this game, they might be resurrected."
      },
      "aiyamdrunk_ppp": {
        "ability": "All other players are sober & healthy. If you are \"mad\" about your character, Townsfolk abilities yield false info, even if you are dead.",
        "homebrew": {
          "name": "Aiyamdrunk",
          "roleType": "townsfolk"
        }
      },
      "notgood_ppp": {
        "ability": "Once per game, the Storyteller will announce that a mistake has been made, but it shouldn't affect the game's outcome.",
        "homebrew": {
          "name": "Not Good",
          "roleType": "outsider"
        }
      },
      "nominatingmyself_ppp": {
        "ability": "You might be resurrected at any time. You might die & might not die at any time.",
        "homebrew": {
          "name": "Nominating Myself",
          "roleType": "outsider",
          "firstNightIndex": 11,
          "otherNightsIndex": 17
        },
        "firstNight": "The Nominating Myself might die.",
        "otherNights": "The Nominating Myself might die. If dead they might be resurrected."
      },
      "oneofthesethree_ppp": {
        "ability": "You might register as your living neighbors' characters, & as an evil Minion.",
        "homebrew": {
          "name": "One'ofthese'Three",
          "roleType": "outsider"
        }
      },
      "mad_ppp": {
        "ability": "You think you are a character not in this script, but you are not. Your ability might work, if your team is losing.",
        "homebrew": {
          "name": "Mad",
          "roleType": "outsider",
          "firstNightIndex": 6,
          "otherNightsIndex": 5
        },
        "firstNight": "The Mad chooses a player or is shown any information needed based on the ability.",
        "otherNights": "The Mad chooses a player or is shown any information needed based on the ability."
      },
      "newplayer_ppp": {
        "ability": "Each night, choose a player: they are drunk tonight and tomorrow day.",
        "homebrew": {
          "name": "New Player",
          "roleType": "minion",
          "firstNightIndex": 4,
          "otherNightsIndex": 2
        },
        "firstNight": "The New Player chooses a player.",
        "otherNights": "The New Player chooses a player."
      },
      "lying_ppp": {
        "ability": "Players publicly 'mad' about being a character they are not might be executed. You register as Good. [+1 or -1 Outsider]",
        "homebrew": {
          "name": "Lying",
          "roleType": "minion"
        }
      },
      "anything_ppp": {
        "ability": "Each day you may visit the Storyteller to learn & gain a new ability, & lose the previous.",
        "homebrew": {
          "name": "Anything",
          "roleType": "minion",
          "otherNightsIndex": 9
        },
        "otherNights": "The Anything chooses a player or is shown any information needed based on the ability."
      },
      "inplay_ppp": {
        "ability": "Once per game, you may publicly guess all players' characters. If all good player guesses are correct, your team wins.",
        "homebrew": {
          "name": "In-Play",
          "roleType": "minion"
        }
      },
      "impish_ppp": {
        "ability": "Each night*, choose a player: they die. The 1st \"new player\" this kills becomes an evil \"Imp!\" & you die instead.",
        "homebrew": {
          "name": "Impish",
          "roleType": "demon",
          "otherNightsIndex": 11
        },
        "otherNights": "The Impish chooses a player. If they choose a New Player (once only): Replace the New Player's character token with the Imp! token. Put the Impish to sleep. Wake the target. Show the YOU ARE and the Imp! token & give a thumbs-down."
      },
      "regularimp_ppp": {
        "ability": "Each night*, choose a player: they might die. The 1st time you die, a Minion becomes Impish.",
        "homebrew": {
          "name": "Regular Imp",
          "roleType": "demon",
          "otherNightsIndex": 10
        },
        "otherNights": "The Regular Imp chooses a player. If the Regular Imp is dead (once only): Replace 1 alive Minion token with the Impish token. Wake the Impish. Show the YOU ARE token, then show the Impish."
      },
      "notanimpgame_ppp": {
        "ability": "Each night, choose a player: they are poisoned. The previously chosen player dies then becomes healthy.",
        "homebrew": {
          "name": "Not An Imp Game",
          "roleType": "demon",
          "firstNightIndex": 5,
          "otherNightsIndex": 3
        },
        "firstNight": "The Not An Imp Game chooses a player.",
        "otherNights": "The Not An Imp Game chooses a player. The previously poisoned player dies then becomes healthy."
      },
      "imp_ppp": {
        "ability": "Each night*, choose a player: they die. If you kill yourself this way, a Minion becomes the Regular Imp",
        "homebrew": {
          "name": "Imp!",
          "roleType": "demon",
          "otherNightsIndex": 12
        },
        "otherNights": "The Imp! chooses a player. If the Imp! chose htemselves: Replace 1 alive Minion token with the Regular Imp token. Put the Imp! to sleep. Wake the Regular Imp. Show the YOU ARE token, then show the Regular Imp token."
      },
      "doubleclaim_ppp": {
        "ability": "You start knowing 2 secret words you cannot share. The 1st player in your alignment to publicly say that word becomes a new traveller & you become their character.",
        "homebrew": {
          "name": "Double Claim",
          "roleType": "travellers"
        }
      },
      "makinthisup_ppp": {
        "ability": "You don't know your ability or your task. Each day, privately ask 1 question about the task & receive a hint. If you completed your task, tonight you learn your ability.",
        "homebrew": {
          "name": "Makin' This Up",
          "roleType": "travellers"
        }
      },
      "noone_ppp": {
        "ability": "Each night gain a new traveller ability. A player of the same alignment learns which.",
        "homebrew": {
          "name": "No-One",
          "roleType": "travellers"
        }
      },
      "leavingshortly_ppp": {
        "ability": "Players (not yourself) that nominate you, die.",
        "homebrew": {
          "name": "Leaving Shortly",
          "roleType": "travellers"
        }
      }
    },
    "script": {
      "pk": 11619,
      "title": "Pedagogic Pits & Pendulums",
      "author": "Gordy",
      "score": 0,
      "characters": [
        "démone_ppp",
        "minion_ppp",
        "notanoutsider_ppp",
        "evil_ppp",
        "acharacter_ppp",
        "poisoned_ppp",
        "townsfolk_ppp",
        "storyteller_ppp",
        "hardclaiming_ppp",
        "outsider_ppp",
        "executed_ppp",
        "notdead_ppp",
        "aiyamdrunk_ppp",
        "notgood_ppp",
        "nominatingmyself_ppp",
        "oneofthesethree_ppp",
        "mad_ppp",
        "newplayer_ppp",
        "lying_ppp",
        "anything_ppp",
        "inplay_ppp",
        "impish_ppp",
        "regularimp_ppp",
        "notanimpgame_ppp",
        "imp_ppp",
        "doubleclaim_ppp",
        "makinthisup_ppp",
        "noone_ppp",
        "leavingshortly_ppp"
      ]
    }
  },
  {
    "id": "sad_moon_rising",
    "characters": {
      "granddad_smr": {
        "ability": "You start knowing another player and your character. If the Demon kills you, they die too.",
        "homebrew": {
          "name": "Grand Dad",
          "roleType": "townsfolk",
          "firstNightIndex": 9,
          "otherNightsIndex": 18
        },
        "firstNight": "Show the Grand Dad token. Point to the marked player.",
        "otherNights": "If the Grand Dad was killed by the Demon tonight: the Grand Dad's grandchild dies."
      },
      "pirate_smr": {
        "ability": "Each night, choose a player: both of you are drunk until dusk. Nobody can die.",
        "homebrew": {
          "name": "Pirate",
          "roleType": "townsfolk",
          "firstNightIndex": 4,
          "otherNightsIndex": 1
        },
        "firstNight": "The Pirate points to a living player. Both players are drunk.",
        "otherNights": "The Pirate points to a living player. Both players are drunk."
      },
      "bedroom_smr": {
        "ability": "Each night, choose 2 dead players: you learn how many died tonight.",
        "homebrew": {
          "name": "Bedroom",
          "roleType": "townsfolk",
          "firstNightIndex": 10,
          "otherNightsIndex": 19
        },
        "firstNight": "The Bedroom points to two dead players. Show the number signal (0, 1, 2,...) for how many of those players died tonight.",
        "otherNights": "The Bedroom points to two dead players. Show the number signal (0, 1, 2,...) for how many of those players died tonight."
      },
      "briefcase_smr": {
        "ability": "Each night*, choose a player: the Demon, if chosen, learns who you are.",
        "homebrew": {
          "name": "Briefcase",
          "roleType": "townsfolk",
          "otherNightsIndex": 7
        },
        "otherNights": "The Briefcase points to a player. If that player is the Demon: Wake the Demon. Show THIS PLAYER IS and the Briefcase token."
      },
      "outkeeper_smr": {
        "ability": "Each night*, choose 2 players: they can't wake up tonight, but 1 is drunk until dusk.",
        "homebrew": {
          "name": "Outkeeper",
          "roleType": "townsfolk",
          "otherNightsIndex": 3
        },
        "otherNights": "The Outkeeper points to two players, who do not wake tonight. One is drunk."
      },
      "gambler_smr": {
        "ability": "Each night*, roll a dice & guess the result. If you guess wrong, you die.",
        "homebrew": {
          "name": "gambler :(",
          "roleType": "townsfolk",
          "otherNightsIndex": 4
        },
        "otherNights": "The gambler :( rolls a dice and guesses the result. If incorrect, the gambler :( dies."
      },
      "speaker_smr": {
        "ability": "Each day, you may make a public statement.",
        "homebrew": {
          "name": "Speaker",
          "roleType": "townsfolk",
          "otherNightsIndex": 8
        },
        "otherNights": "The Townsfolk chooses a player or is shown any information needed based on the ability."
      },
      "curtain_smr": {
        "ability": "Once per game, at night, choose a character: They don't wake up for 3 nights and 3 days.",
        "homebrew": {
          "name": "Curtain",
          "roleType": "townsfolk",
          "firstNightIndex": 5,
          "otherNightsIndex": 2
        },
        "firstNight": "The Curtain either shows a 'no' head signal, or points to a character on the sheet. If the Curtain used their ability: if that character is in play, that player cannot wake.",
        "otherNights": "The Curtain either shows a 'no' head signal, or points to a character on the sheet. If the Curtain used their ability: if that character is in play, that player cannot wake."
      },
      "teacher_smr": {
        "ability": "Once per game, at night*, choose a dead player: they now register as being alive.",
        "homebrew": {
          "name": "Teacher",
          "roleType": "townsfolk",
          "otherNightsIndex": 15
        },
        "otherNights": "If the Teacher has not used their ability: The Teacher either shakes their head no, or points to a player. That player now registers as being alive."
      },
      "smarties_smr": {
        "ability": "When a player dies, all other players are drunk until dusk tomorrow.",
        "homebrew": {
          "name": "Smarties",
          "roleType": "townsfolk",
          "otherNightsIndex": 16
        },
        "otherNights": "If the Outsider died tonight, set-up Grimoire as needed. Show the Grimoire for 1 second. Reset Grimoire."
      },
      "coffeelady_smr": {
        "ability": "If both your neighbors are alive, they can't die.",
        "homebrew": {
          "name": "Coffee Lady",
          "roleType": "townsfolk",
          "otherNightsIndex": 14
        },
        "otherNights": "If Executed was executed today, show 2 character tokens that voted."
      },
      "pacifier_smr": {
        "ability": "Players might not die.",
        "homebrew": {
          "name": "Pacifier",
          "roleType": "townsfolk",
          "otherNightsIndex": 15
        },
        "otherNights": "If the Not Dead died, show a dead character token. If the Not Dead was executed twice this game, they might be resurrected."
      },
      "fuel_smr": {
        "ability": "The second time you die, you are resurrected.",
        "homebrew": {
          "name": "Fuel",
          "roleType": "townsfolk",
          "otherNightsIndex": 17
        },
        "otherNights": "If the Fuel has died twice, they are resurrected."
      },
      "tinkle_smr": {
        "ability": "You will die.",
        "homebrew": {
          "name": "Tinkle",
          "roleType": "outsider",
          "otherNightsIndex": 16
        },
        "otherNights": "The Tinkle will die."
      },
      "sunchild_smr": {
        "ability": "When you learn that you live, publicly choose 1 alive player. If good, they die.",
        "homebrew": {
          "name": "Sunchild",
          "roleType": "outsider"
        }
      },
      "gun_smr": {
        "ability": "Each night, the 1st player to choose you dies. You become the winning team's alignment.",
        "homebrew": {
          "name": "Gun",
          "roleType": "outsider",
          "firstNightIndex": 14,
          "otherNightsIndex": 14
        },
        "firstNight": "Kill the first player who chose the Gun tonight.",
        "otherNights": "Kill the first player who chose the Gun tonight."
      },
      "fanatic_smr": {
        "ability": "You think you are a Demon, but are. The Demon knows you are & who you choose at night & your home address.",
        "homebrew": {
          "name": "Fanatic",
          "roleType": "outsider",
          "firstNightIndex": 3,
          "otherNightsIndex": 6
        },
        "firstNight": "If 7 or more players: Show THESE ARE YOUR MINIONS. Point to all Minions. Show THESE CHARACTERS ARE NOT IN PLAY and three bluffs. Wake the other Demon. Show THIS PLAYER IS and their Demon token, point to the Fanatic.",
        "otherNights": "Do whatever the Demon usually does."
      },
      "codfather_smr": {
        "ability": "You start knowing which players are playing. If 1 was executed today, they are replaced by someone else tonight. [-1 or +1 Player]",
        "homebrew": {
          "name": "Codfather",
          "roleType": "minion",
          "firstNightIndex": 6,
          "otherNightsIndex": 13
        },
        "firstNight": "Show each of the players in play.",
        "otherNights": "If a player died today: replace them with another player."
      },
      "devilsavocado_smr": {
        "ability": "Each night, choose a player. The storyteller will publicly discourage town from executing them.",
        "homebrew": {
          "name": "Devil's Avocado",
          "roleType": "minion",
          "firstNightIndex": 7,
          "otherNightsIndex": 5
        },
        "firstNight": "The Devil's Avocado points to a living player. The storyteller will discourage the town from executing that player tomorrow.",
        "otherNights": "The Devil's Avocado points to a living player. The storyteller will discourage the town from executing that player tomorrow."
      },
      "assassassin_smr": {
        "ability": "Once per game, you may murder a player.",
        "homebrew": {
          "name": "Assassassin",
          "roleType": "minion",
          "otherNightsIndex": 12
        },
        "otherNights": "If the Assassassin has not yet used thier ability: The Assassassin either shows the 'no' head signal, or goes and murders another player."
      },
      "mistermind_smr": {
        "ability": "When the game ends, play for one more day.",
        "homebrew": {
          "name": "Mister Mind",
          "roleType": "minion"
        }
      },
      "zombie_smr": {
        "ability": "Each day, if a player died last night, publicly choose a player. They become a Zombie. The first time you die, you die.",
        "homebrew": {
          "name": "Zombie",
          "roleType": "demon",
          "otherNightsIndex": 8
        },
        "otherNights": "If a player died last night and the Zombie publicly chose a player. Wake that player. Show YOU ARE and the Zombie token."
      },
      "fucker_smr": {
        "ability": "Each night, choose a player: they die. The previously killed player is removed from the game.",
        "homebrew": {
          "name": "Fucker",
          "roleType": "demon",
          "firstNightIndex": 8,
          "otherNightsIndex": 9
        },
        "firstNight": "The Fucker points to a player. That player is dead.",
        "otherNights": "The Fucker points to a player. That player is dead. The previously dead player is removed from the game."
      },
      "shbalath_smr": {
        "ability": "monch monch*. but burp?",
        "homebrew": {
          "name": "Shbalath",
          "roleType": "demon",
          "otherNightsIndex": 10
        },
        "otherNights": "The shbalath points to two players. Those players die. There might be a burp."
      },
      "poo_smr": {
        "ability": "Each night, you may choose 3 players: they might die. If your last choice was no-one, you die.",
        "homebrew": {
          "name": "Poo",
          "roleType": "demon",
          "otherNightsIndex": 11
        },
        "otherNights": "If the Poo chose no-one the previous night: they die. The poo points to three players. Chosen players might die."
      }
    },
    "script": {
      "pk": 17658,
      "title": "Sad Moon Rising",
      "author": "Steven Medway",
      "score": 0,
      "characters": [
        "granddad_smr",
        "pirate_smr",
        "bedroom_smr",
        "briefcase_smr",
        "outkeeper_smr",
        "gambler_smr",
        "speaker_smr",
        "curtain_smr",
        "teacher_smr",
        "smarties_smr",
        "coffeelady_smr",
        "pacifier_smr",
        "fuel_smr",
        "tinkle_smr",
        "sunchild_smr",
        "gun_smr",
        "fanatic_smr",
        "codfather_smr",
        "devilsavocado_smr",
        "assassassin_smr",
        "mistermind_smr",
        "zombie_smr",
        "fucker_smr",
        "shbalath_smr",
        "poo_smr"
      ]
    }
  }
]